<template>
  <v-container>
    <!-- <p>{{ params.body }}</p> -->
    <v-form ref="form">
      <v-text-field
        v-model="params.item.stock"
        :label="$t('product.stock')"
        hide-details
        dense
        class="mb-3"
      ></v-text-field>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "ProductStockDialog",
  model: {
    prop: "params",
    event: "input",
  },
  props: {
    params: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
};
</script>
