<template>
  <view-container breadcrumbs scroll-fab>
    <v-card flat>
      <toolbar :title="$t('admin.route.products')">
        <v-text-field
          class="d-none d-md-flex mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width: 0"
        ></v-text-field>
        <v-divider vertical />
        <v-btn text small color="primary" @click="editStock()">
          <v-icon>mdi-counter</v-icon>
          <span class="text--secondary">{{ $t("product.stock") }}</span>
        </v-btn>
        <v-divider vertical />
        <v-btn
          text
          small
          color="green"
          @click="newItem"
        >
          <v-icon>mdi-folder-plus</v-icon>
          <span class="text--secondary">{{ $t("general.new") }}</span>
        </v-btn>
        <v-btn
          text
          small
          color="primary"
          :disabled="!selected"
          @click="editItem"
        >
          <v-icon>mdi-file-edit-outline</v-icon>
          <span>{{ $t("general.edit") }}</span>
        </v-btn>
        <v-btn
          text
          small
          color="red"
          v-if="$hasRole('SUPER-ADMIN') || $hasRole('ADMIN')"
          :disabled="!selected"
          @click="confirmDelete"
        >
          <v-icon>mdi-delete</v-icon>
          <span>{{ $t("general.delete") }}</span>
        </v-btn>
      </toolbar>

      <v-card-text
        class="overflow-y-auto px-0 py-0"
        :style="getViewHeightStyle(84)"
      >
        <v-data-table
          dense
          :headers="headers"
          :items="getStoreProducts"
          :items-per-page="getPageItemsCount"
          item-key="id"
          :search="search"
          :custom-filter="deepFilter"
          show-select
          single-select
          :value="selection"
          :loading="loading"
          :loading-text="$t('messages.loading')"
          @item-selected="selectionChanged"
          @click:row="selectionChanged"
        >
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              on-icon="mdi-checkbox-marked-circle"
              off-icon="mdi-power-off"
              :value="isSelected"
              color="primary"
              light
              @input="select($event)"
              :ripple="false"
            ></v-simple-checkbox>
          </template>

          <!-- <template #header.id="{ header }">{{ $t(header.text) }}</template> -->
          <template #header.username="{ header }">
            <span class="ml-9">{{ header.text }}</span>
          </template>
          <!-- <template #header.name="{ header }">{{ $t(header.text) }}</template>
          <template #header.email="{ header }">{{ $t(header.text) }}</template>
          <template #header.roles="{ header }">{{ $t(header.text) }}</template> -->

          <template #item.name="{ item }">
            <v-list flat color="transparent">
              <v-list-item :key="item.name">
                <v-list-item-avatar>
                  <img
                    v-if="notEmptyArray(item.images)"
                    alt="Avatar"
                    :src="getImage(item)"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                  <!-- <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>

          <template #item.price="{ item }">
            <span>{{ item.unitPrice }}</span
            ><span v-if="item.unitType"> ({{ item.unitType }})</span>
          </template>
          <template #item.sectionCategory="{ item }">
            <span v-if="item.category && item.category.section"
              >{{ item.category.section.name }} /
            </span>
            <span v-if="item.category">{{ item.category.name }}</span>
          </template>
          <template #item.tags="{ item }">
            <v-chip
              v-if="notEmptyArray(item.tags)"
              v-for="tag in item.tags"
              :key="tag"
              class="ma-1 px-1"
              small
              label
            >
              {{ tag }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import Toolbar from "@/common/components/Toolbar";
import ProductStockDialog from "./ProductStockDialog";

export default {
  name: "ProductsList",
  components: {
    ViewContainer,
    Toolbar,
    ProductStockDialog,
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      search: "",
      loading: false,
      deleteDialog: false,
      headers: [
        { text: this.$t("general.id"), align: "start", value: "id", width: 70 },
        { text: this.$t("general.name"), value: "name" },
        { text: this.$t("product.price"), value: "unitPrice", width: 120 },
        { text: this.$t("product.stock"), value: "stock", width: 120 },
        { text: this.$t("product.sectionCategory"), value: "sectionCategory" },
        { text: this.$t("product.tags"), value: "tags" },
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.loadStore(this.getStoreIdParam);
    this.clearSelectedStoreProduct();
    this.loadStoreProductsByParams({
      storeId: this.getStoreIdParam,
      ids: null,
    }).then(() => {
      this.loading = false;
    });
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    // this.setReactivityParams({
    //   resource: "PRODUCTS",
    //   scope: "store",
    //   key: this.getStoreIdParam,
    // });
    this.$sub("reactivity/PRODUCTS", (payload) => {
      this.loading = true;
      if (payload.mode !== "Delete") {
        this.reloadStoreProductsByParams({
          storeId: this.getStoreIdParam,
          ids: [payload.product],
        }).then(() => (this.loading = false));
      } else {
        this.removeStoreProductsFromState(payload.product).then(
          () => (this.loading = false)
        );
      }
      console.log("reactivity/PRODUCTS:", payload);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);

    this.$unsubAll();
    this.clearReactivityParams();
  },
  computed: {
    ...mapGetters("products", ["getStoreProducts", "getStoreProductsByProp"]),
    ...mapGetters("stores", ["getStoreById"]),

    ...mapState({
      selected(state) {
        return state.products.selected;
      },
      selection() {
        const s = this.selected;
        return s ? [s] : [];
      },
    }),

    getPageItemsCount() {
      return Math.round((this.windowHeight - 193) / 73) - 1;
    },

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },

    getStore() {
      return this.getStoreById(this.getStoreIdParam);
    },
    // parentIdParam() {
    //   return parseInt(this.$route.params.categoryId);
    // },
  },
  methods: {
    ...mapActions("products", [
      "loadStoreProductsByParams",
      "reloadStoreProductsByParams",
      "removeStoreProductsFromState",
      "deleteStoreProduct",
      "setSelectedStoreProduct",
      "clearSelectedStoreProduct",
      "updateStoreProduct",
    ]),
    ...mapActions("ui", [
      "showDialog",
      "closeDialog",
      "setReactivityParams",
      "clearReactivityParams",
    ]), //, "setBreadcrumbs"
    ...mapGetters("stores", ["loadStore"]),

    // refreshBreadcrumb() {
    //   const updatedBreadcrumbs = this.$route.meta.breadcrumbs.map((item) => {
    //     if (item.dynamic && this.category) {
    //       return { ...item, name: this.org.acronym || this.org.name };
    //     }
    //     return item;
    //   });
    //   this.setBreadcrumbs(updatedBreadcrumbs);
    // },

    selectionChanged(event) {
      if (event.value) {
        this.setSelectedStoreProduct(event.item);
      } else if (event.id && this.selected?.id !== event.id) {
        this.setSelectedStoreProduct(event);
      } else {
        this.clearSelectedStoreProduct();
      }
    },
    newItem() {
      this.$$router.push({
        name: "manager.store.product",
        params: { productUuid: "new", back: { name: this.$route.name } },
      });
    },
    editItem() {
      if (this.selected) {
        this.$$router.push({
          name: "manager.store.product",
          params: { productUuid: this.selected.uuid, back: { name: this.$route.name } },
        });
      }
    },
    confirmDelete() {
      if (this.selected) {
        // this.deleteDialog = true;
        const dialog = {
          title: "Delete",
          text: `Are you sure you want to delete ${this.selected.name} product?`,
          actions: [
            {
              text: "Yes",
              click: () => {
                this.deleteStoreProduct(this.selected.id);
                this.closeDialog();
              },
              color: "red",
            },
            {
              text: "No",
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };

        this.showDialog(dialog);
      }
    },
    getImage(item) {
      return this.notEmptyArray(item.images) && this.getStore
        ? `${process.env.VUE_APP_API_URL}/files/image/?path=stores/_${this.getStore.uuid}/${item.images[0]}`
        : null;
    },
    deepFilter(value, search, item) {
      return (
        value != null &&
        search != null &&
        (value
          .toString()
          .toLocaleLowerCase()
          .indexOf(search.toLocaleLowerCase()) !== -1 ||
          (item && item.barcode && item.barcode.indexOf(search) !== -1) ||
          (item &&
            item.description &&
            item.description
              .toLocaleLowerCase()
              .indexOf(search.toLocaleLowerCase()) !== -1) ||
          (item.category &&
            item.category.name
              .toLocaleLowerCase()
              .indexOf(search.toLocaleLowerCase()) !== -1) ||
          (item.category.section &&
            item.category.section.name
              .toLocaleLowerCase()
              .indexOf(search.toLocaleLowerCase()) !== -1))
      );
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    editStock() {
      if (this.selected) {
        const { id: productId, stock } = this.selected;
        const dialog = {
          title: this.$t("product.stock"),
          component: ProductStockDialog,
          params: {
            item: { productId, stock },
          },
          width: 450,
          actions: [
            {
              text: this.$t("general.okay"),
              click: ({ item }) => {
                if (item.stock !== this.selected.stock) {
                  this.updateStoreProduct({
                    url: "/stock",
                    item,
                  });
                }

                this.closeDialog();
              },
              color: "primary",
            },
            {
              text: this.$t("general.cancel"),
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };
        this.showDialog(dialog);
      }
    },
  },
};
</script>
